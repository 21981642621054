import React from 'react';

// Import helpers
import {
	getFormattedFilterValues,
	INITIAL_VALUES,
} from 'views/Invoices/InvoicesList/helpers';

// Import components
import { TableQueryExportFilters } from 'components/elements';
import { DialogFilters } from '../Filters';

export const ExportFilters = () => (
	<TableQueryExportFilters
		initialValues={INITIAL_VALUES}
		formatValues={getFormattedFilterValues}
	>
		<DialogFilters />
	</TableQueryExportFilters>
);
