import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Table as MaterialTable,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { array, bool, element, number, object, string } from 'prop-types';
import { get } from 'lodash';
import clsx from 'clsx';

// Import components
import { Filters, HeadCell, Pagination, Search } from './components';
import { Grid, Typography } from 'components/elements';

// Import styles
import { useStyles } from './Wrapper.styles';

// Import utils
import { useTableRPCWithQueryProvider } from '../../context';
import { useTranslations } from 'components/utilities';

export const Wrapper = ({
	title = '',
	rowsPerPageOptions,
	titleFontSize = 28,
	removeContainerPadding = false,
	titleCustomStyles = '',
	showSearch = true,
	filters = null,
	filtersInitialValues = {},
	headerContainerMinHeight,
	outerToolbarElements = null,
}) => {
	const classes = useStyles({ headerContainerMinHeight });

	const { t } = useTranslations();

	const { currentPageRowsCount, loading, data, columns } =
		useTableRPCWithQueryProvider();

	const filteredColumns = columns.filter((column) => column);

	return (
		<Box
			className={clsx({
				[classes.container]: !removeContainerPadding,
			})}
		>
			<Box className={classes.headerContainer}>
				{title && (
					<Typography
						className={titleCustomStyles}
						fontSize={titleFontSize}
						fontWeight={500}
					>
						{t(title)}
					</Typography>
				)}
				{outerToolbarElements}
			</Box>
			<Card className={classes.root}>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					spacing={2}
					className={classes.filtersWrapper}
				>
					{filters && (
						<Grid item>
							<Filters
								filters={filters}
								filtersInitialValues={filtersInitialValues}
							/>
						</Grid>
					)}
					{showSearch && (
						<Grid item>
							<Search />
						</Grid>
					)}
				</Grid>
				<CardContent className={classes.content}>
					{loading && (
						<div className={classes.loader}>
							<CircularProgress size={48} color="primary" />
						</div>
					)}

					{!loading && currentPageRowsCount === 0 && (
						<Typography className={classes.noData} variant="subtitle1">
							{t('table.no_data')}
						</Typography>
					)}
					<PerfectScrollbar>
						<div className={classes.inner}>
							<MaterialTable aria-label="simple table">
								<TableHead className={classes.tHead}>
									<TableRow>
										{filteredColumns.map((column) => (
											<HeadCell column={column} key={column.header} />
										))}
									</TableRow>
								</TableHead>
								{!!currentPageRowsCount && (
									<TableBody>
										{data.map((row, index) => (
											<TableRow key={index}>
												{filteredColumns.map((column) => {
													return (
														<TableCell key={column.accessor}>
															{column.component
																? column.component(row)
																: get(row, column.accessor)}
														</TableCell>
													);
												})}
											</TableRow>
										))}
									</TableBody>
								)}
							</MaterialTable>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{!loading && (
					<CardActions>
						<Pagination rowsPerPageOptions={rowsPerPageOptions} />
					</CardActions>
				)}
			</Card>
		</Box>
	);
};

Wrapper.propTypes = {
	title: string,
	rowsPerPageOptions: array,
	titleFontSize: number,
	removeContainerPadding: bool,
	titleCustomStyles: string,
	showSearch: bool,
	filters: element,
	filtersInitialValues: object,
	headerContainerMinHeight: string,
	outerToolbarElements: element,
};
