export const URLS = {
	dashboardUrl: '/dashboard',
	editBookingUrl: '/bookings/:id/edit',
	previewBookingUrl: '/bookings/:id/preview',
	bookingsUrl: '/bookings',
	extendedBookingsUrl: '/extended_bookings',
	transferredBookingsUrl: '/transferred_bookings',
	printBookingUrl: '/bookings/:id/print-details',
	createContractUrl: '/bookings/:id/contract/create',
	ViewContractDraftUrl: '/bookings/:id/contract/draft',
	contractUrl: '/bookings/:id/contract/view',
	extendContractUrl: '/bookings/:id/contract/extend',
	closeContractUrl: '/bookings/:id/contract/close',
	suspendContractUrl: '/bookings/:id/contract/suspend',
	scheduledBookingsUrl: '/scheduled_booking',
	lateDeliveryBookingsUrl: '/late_delivery_bookings',
	lastBookingUrl: '/last_booking',
	carsUrl: '/offices/:id/cars',
	editCarUrl: '/offices/:id/car/:carId/edit',
	globalEditCarsUrl: '/offices/cars/edit',
	globalEditCarsUrlWithUuid: '/offices/cars/edit/:companyUuid',
	addCarUrl: '/offices/:id/car/add',
	officeExternalPartnerCars: '/offices/:id/external-partner-cars',
	externalPartnersList: '/company/:id/external-partner-cars',
	globalAddCarUrl: '/offices/car/add/',
	globalAddCarUrlWithUuid: '/offices/car/add/:companyUuid',
	createOfficeUrl: '/offices/create',
	editOfficeUrl: '/offices/:id/edit',
	officeDetailsUrl: '/offices/:id/details',
	officesUrl: '/offices',
	customerPreviewUrl: '/customer/:id/preview',
	editCustomerUrl: '/customer/:id/edit',
	customersUrl: '/customers/list',
	customerRegistrationUrl: '/customers/registration',
	addCustomerRegistrationUrl: '/customers/registration/add',
	companyOfficesUrl: '/company/:id/offices',
	companyUsersUrl: '/company/:id/users',
	editCompanyUrl: '/company/:id/edit',
	createCompanyUrl: '/company/companies-list/create',
	companiesListUrl: '/company/companies-list',
	customerBalanceUrl: '/customer-balance',
	subscriptionBalanceUrl: '/subscription-balance',
	editProfileUrl: '/profile/edit',
	editCompanyManagersUrl: '/company-managers/:id/edit',
	createCompanyManagerUrl: '/company-managers/create',
	companyManagersUrl: '/company-managers',
	employeeRewardingSettingsUrl: '/company/:id/employee_rewarding',
	employeeRewardingUsersUrl: '/employee_rewarding_users',
	employeeRewardingUsersWithIdUrl: '/employee_rewarding_users/:id',
	employeeRewardingBookingsWithIdUrl:
		'/employee_rewarding_bookings/:id/company/:companyUuid',
	employeeRewardingBookingsUrl: '/employee_rewarding_bookings',
	publicHolidaysUrl: '/company/public-holidays',
	editOrganizationDiscountUrl:
		'/organization/:organizationId/discount/:id/edit',
	addOrganizationDiscountUrl: '/organization/:id/discount/add',
	organizationDiscountsUrl: '/organization/:id/discounts',
	organizationsUrl: '/organizations',
	editCancellationReasonUrl: '/cancellationReason/:id/edit',
	addCancellationReasonUrl: '/cancellationReason/add',
	cancellationReasonsUrl: '/cancellationReasons',
	editPromoCodeUrl: '/promoCode/:id/edit',
	addPromoCodeUrl: '/promoCode/add',
	promoCodesUrl: '/promoCodes',
	promoCodeUrl: '/promoCodes/:id',
	editCarModelUrl: '/cars/model/:id/edit',
	addCarModelUrl: '/cars/model/add',
	carModelsUrl: '/cars/models',
	editCarManufacturerUrl: '/cars/manufacturer/:id/edit',
	addCarManufacturerUrl: '/cars/manufacturer/add',
	carManufacturerUrl: '/cars/manufacturer',
	editCarListUrl: '/cars/car-list/:id/edit',
	addCarListUrl: '/cars/car-list/add',
	carListUrl: '/cars/car-list',
	editSliderUrl: '/slider/:id/edit',
	addSliderUrl: '/slider/add',
	slidersUrl: '/sliders',
	editSpecialOfferUrl: '/special-offer/:id/edit/:type',
	addSpecialOfferUrl: '/special-offer/add/:type',
	specialOfferUrl: '/special-offer',
	editUserUrl: '/user/:id/edit',
	addUserUrl: '/user/add',
	usersUrl: '/users',
	addDriverUrl: '/driver/add',
	editDriverUrl: '/driver/:id/edit',
	driversUrl: '/drivers',
	driverLicenseList: '/drivers-license-list',
	editCmsPageUrl: '/cms/page/:id/edit',
	addCmsPageUrl: '/cms/page/add',
	editCmsPartnersUrl: '/cms/partners/:id/edit',
	addCmsPartnersUrl: '/cms/partners/add',
	cmsPagesUrl: '/cms/pages',
	cmsPartnersUrl: '/cms/partners',
	cmsPartnersPositionsUrl: '/cms/partners-positions',
	cmsCitiesUrl: '/cms/cities',
	editDefaultSeoUrl: '/default-seo/:id/edit',
	companyDropCitiesUrl: '/company/drop-cities/:id',
	companyDropCitiesListUrl: '/company/drop-cities',
	editRatingReasonsUrl: '/rating-reasons/:id/edit',
	addRatingReasonsUrl: '/rating-reasons/add',
	ratingReasonsUrl: '/rating-reasons',
	editUrgentReasonsUrl: '/urgent-reasons/:id/edit',
	addUrgentReasonsUrl: '/urgent-reasons/add',
	urgentReasonsUrl: '/urgent-reasons',
	urgentBookingsUrl: '/urgent-bookings',
	compensationValidationUrl: '/compensation-validation',
	cancellationValidationUrl: '/cancellation-validation',
	cancellationByCustomerUrl: '/cancellation-by-customer',
	waitingCustomerInfoUrl: '/waiting-customer-info',
	accountingValidationUrl: '/accounting-validation',
	reportsUrl: '/reports',
	carsAvailabilityUrl: '/cars-availability',
	mobileAppSystemClientsUrl: '/mobile-app/system-clients',
	kioskAirportsUrl: '/kiosk/airports',
	editKioskAirportUrl: '/kiosk/airports/:id/edit',
	createKioskAirportUrl: '/kiosk/airports/create',
	airportTerminalsUrl: '/kiosk/airports/:id/terminals',
	editAirportTerminalUrl: '/kiosk/airports/:airportId/terminals/:id/edit',
	createAirportTerminalUrl: '/kiosk/airports/:airportId/terminals/create',
	kioskManagementUrl: '/kiosk/management',
	editKioskUrl: '/kiosk/management/:id/edit',
	createKioskUrl: '/kiosk/management/create',
	campaignsUrl: '/campaigns',
	editCampaignUrl: '/campaigns/:id/edit',
	addCampaignUrl: '/campaigns/add/:type',
	corporateListUrl: '/corporate/list',
	editCorporateUrl: '/corporate/:id/edit',
	addCorporateUrl: '/corporate/add',
	corporateOverviewUrl: '/corporate-overview',
	addCorporateCustomerUrl: '/corporate-overview/customer/add',
	editCorporateCustomerUrl: '/corporate-overview/customer/:id/edit',
	corporateMaintenanceUrl: '/corporate-maintenance',
	registrationRequestsUrl: '/registration-requests',
	corporateSettlementsUrl: '/corporate/:id/settlements',
	unsettledBookingsUrl: '/corporate/:id/unsettled-bookings',
	corporateBookingsUrl: '/corporate/:id/bookings',
	rentalCompaniesUrl: '/corporate/rental-companies',
	rewardsSettlementUrl: '/rewards-settlement',
	addPlateUrl: '/cars/car-list/plate/add',
	editPlateUrl: '/cars/car-list/plate/:id/edit',
	notFoundUrl: '/not-found',
	resetPasswordUrl: '/reset-password',
	setPasswordUrl: '/set-password',
	forgotPasswordUrl: '/forgot-password',
	signInUrl: '/sign-in',
	accountUrl: '/account',
	emptyUrl: '/',
	faqsUrl: '/faqs',
	editFaqUrl: '/faqs/:id/edit',
	createFaqUrl: '/faqs/add',
	subscriptionListUrl: '/subscription_list',
	paymentCollectionUrl: '/payment_collection',
	returnBookingsUrl: '/return_bookings',
	transferreBookingAlternativeCars: (uuid) =>
		`/bookings/${uuid}/edit#alternative-cars`,
	trainStations: '/train-stations',
	addTrainStation: '/train-stations/add',
	editTrainStation: '/train-stations/:uuid/edit',
	financialReportUrl: '/financial-report',
	eventReportUrl: '/event-report',
	helpCenterConfiguration: '/help-center-configuration/:type',
	helpCenterAddForms: '/help-center-configuration/:form/add',
	helpCenterEditViewForms: '/help-center-configuration/:form/:id/:entry',
	leasingCarsUrl: '/offices/:id/leasing-cars',
	addLeasingCarUrl: '/office/:officeId/leasing-car/add',
	editLeasingCarUrl: '/offices/:officeId/leasing-car/:vehicleId/edit',
	leasingCancellation: '/leasing-cancellation',
	createBooking: '/create-booking',
	invoicesListUrl: '/invoices-list',
};
