/* eslint-disable react/prop-types */
import { t } from 'i18next';
import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_TIME_COMMA_FORMAT } from 'helpers';

export const columns = [
	{
		Header: 'common.fields.invoice_number',
		accessor: 'number',
	},
	{
		Header: 'common.fields.type',
		accessor: 'type',
		valueExtractor: (row) =>
			t(`bookings.preview_booking.booking_purpose.${row.purpose}`),
	},
	{
		Header: 'common.fields.company',
		accessor: 'company_name',
	},
	{
		Header: 'common.fields.office',
		accessor: 'office_name',
	},
	{
		Header: 'common.fields.date',
		accessor: 'date',
		valueExtractor: ({ created_at }) =>
			created_at
				? format(new Date(created_at), DEFAULT_DATE_TIME_COMMA_FORMAT)
				: '',
	},
	{
		Header: 'common.fields.booking_id',
		accessor: 'booking_id',
	},
	{
		Header: 'common.fields.customer',
		accessor: 'customer',
		valueExtractor: ({ customer }) => `${customer?.name} / ${customer?.phone}`,
	},
	{
		Header: 'common.fields.amount',
		accessor: 'amount',
		valueExtractor: ({ cash_flow, net_worth }) => `${cash_flow} / ${net_worth}`,
	},
];
