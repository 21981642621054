// Import utils and helpers
import { createExport } from 'helpers';
import { useTableQueryExportProvider } from 'components/context';

export const useTableQueryExport = () => {
	const { type, fetchData, visibleColumns, isLoading } =
		useTableQueryExportProvider();

	const handleExport = async () => {
		const data = await fetchData();

		if (!data) {
			return;
		}

		const tableExport = createExport({ type });

		const file = tableExport({
			data,
			columns: visibleColumns,
		});

		file.download();
	};

	return {
		handleExport,
		isLoading,
	};
};
