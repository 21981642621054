import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '16px',
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
		paddingTop: 0,
		borderRadius: theme.spacing(3),

		'& .MuiTableCell-body': {
			fontSize: '13px',
			textTransform: 'capitalize',
		},
	},
	content: {
		position: 'relative',
		padding: 0,
	},
	inner: {
		minWidth: 1050,
		minHeight: 200,
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		marginRight: theme.spacing(2),
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 1,
		background: alpha(theme.palette.background.default, 0.5),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	noData: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: '20px',
	},
	alignRight: {
		textAlign: 'right',
	},
	alignLeft: {
		textAlign: 'left',
	},
	headerContainer: ({ headerContainerMinHeight }) => ({
		minHeight: headerContainerMinHeight || '100px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	}),
	tHead: {
		backgroundColor: theme.palette.white,
	},
	container: {
		padding: '24px',
	},
	filtersWrapper: {
		paddingTop: '24px',
	},
}));
