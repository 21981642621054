import React from 'react';

// Import components
import { TableExportPopup, TableQueryExport } from 'components/elements';
import { ExportFilters } from '../ExportFilters';

// Import helpers and utils
import { ENDPOINTS, QUERY_KEYS } from 'helpers';
import { columns } from './columns';
import { TableQueryExportProvider } from 'components/context';

export const Export = () => {
	return (
		<TableQueryExportProvider
			columns={columns}
			fetchDataTableUrl={ENDPOINTS.INVOICES.INVOICES_LIST}
			queryKey={QUERY_KEYS.INVOICES.INVOICES_LIST_EXPORT}
		>
			<TableExportPopup isQueryTable />
			<TableQueryExport renderFilters={() => <ExportFilters />} />
		</TableQueryExportProvider>
	);
};
