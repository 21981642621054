import React from 'react';
import { Box, Button, Popover } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { bool } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { TableQueryExportButtons } from '../../TableQueryExport/components';
import TableExportButtons from '../TableExportButtons';

// Import styles
import { useStyles } from './TableExportPopup.styles';

// Import assets
import { DownloadDocumentIcon } from 'assets/icons';

export const TableExportPopup = ({ isQueryTable = false }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<Box>
					<Button
						startIcon={<DownloadDocumentIcon />}
						className={classes.button}
						{...bindTrigger(popupState)}
					>
						{t('common.buttons.export')}
					</Button>
					<Popover
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						{...bindPopover(popupState)}
					>
						{isQueryTable ? (
							<TableQueryExportButtons />
						) : (
							<TableExportButtons />
						)}
					</Popover>
				</Box>
			)}
		</PopupState>
	);
};

TableExportPopup.propTypes = {
	isQueryTable: bool,
};
