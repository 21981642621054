import { array, bool, node, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

// Import helpers and utils
import { EXPORT_TYPES, getErrorMessage } from 'helpers';
import { useChunkedQuery } from './useChunkedQuery';

export const TableQueryExportContext = React.createContext();

export const TableQueryExportProvider = ({
	children,
	columns,
	fetchDataTableUrl,
	columnsLoading = false,
	queryKey,
}) => {
	const cancelTokenRef = React.useRef(null);

	const [state, setState] = useState({
		filters: {},
		columns: columns.map((column) => ({ ...column, isVisible: true })),
		open: false,
		type: EXPORT_TYPES.EXCEL,
		progress: 0,
		error: '',
	});

	const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);

	const visibleColumns = state.columns.filter(({ isVisible }) => isVisible);

	const openExport = (type) => {
		setState((state) => ({
			...state,
			type,
			open: true,
		}));
	};

	const hideExport = () => {
		cancelTokenRef.current && cancelTokenRef.current.cancel();

		setState((state) => ({
			...state,
			type: '',
			open: false,
		}));
	};

	const handleOnSelectAllChange = ({ target: { checked } }) => {
		setState((prevState) => ({
			...prevState,
			columns: prevState.columns.map((column) => ({
				...column,
				isVisible: checked,
			})),
		}));

		setIsSelectAllChecked(checked);
	};

	const toggleColumnVisible = (column) => {
		const index = state.columns.findIndex(
			({ accessor }) => accessor === column.accessor
		);

		setState((state) => ({
			...state,
			columns: [
				...state.columns.slice(0, index),
				{
					...column,
					isVisible: !column.isVisible,
				},
				...state.columns.slice(index + 1),
			],
		}));
	};

	const setFilters = (filters) => {
		setState((state) => ({
			...state,
			filters,
		}));
	};

	useEffect(() => {
		const isEveryColumnVisible = state.columns.every(
			(column) => column.isVisible
		);

		if (isEveryColumnVisible !== isSelectAllChecked) {
			setIsSelectAllChecked(isEveryColumnVisible);
		}
		// eslint-disable-next-line
	}, [state.columns, isSelectAllChecked]);

	const {
		data: allData = [],
		isLoading,
		isError,
		error,
		refetch,
	} = useChunkedQuery({
		fetchDataTableUrl,
		queryKey: [queryKey, state.filters],
		setState,
		state,
	});

	useEffect(() => {
		if (isError) {
			setState((state) => ({
				...state,
				error: getErrorMessage(error),
			}));
		}

		// eslint-disable-next-line
	}, [isError]);

	return (
		<TableQueryExportContext.Provider
			value={{
				openExport,
				hideExport,
				columnsLoading,
				isSelectAllChecked,
				onSelectAllChange: handleOnSelectAllChange,
				visibleColumns,
				toggleColumnVisible,
				setFilters,
				fetchData: refetch,
				isLoading,
				allData,
				...state,
			}}
		>
			{children}
		</TableQueryExportContext.Provider>
	);
};

TableQueryExportProvider.propTypes = {
	children: node.isRequired,
	columns: array.isRequired,
	fetchDataTableUrl: string.isRequired,
	columnsLoading: bool,
	queryKey: string.isRequired,
};
