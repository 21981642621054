import { useQuery } from 'react-query';
import myAxios from 'services/myAxiosFactory';

export const useChunkedQuery = ({
	fetchDataTableUrl,
	queryKey,
	setState,
	state,
}) => {
	const axios = myAxios();
	const fetchChunk = async ({ page = 1, perPage = 100 }) => {
		const { data } = await axios.get(fetchDataTableUrl, {
			params: {
				...state.filters,
				page,
				per_page: perPage,
			},
		});
		const metadata = data.metadata;
		const totalPages = Math.ceil(metadata.total / perPage);
		const hasMore = totalPages > page;

		const progress = (page / totalPages) * 100;

		setState((state) => ({
			...state,
			progress,
		}));

		if (hasMore) {
			const nextChunkData = await fetchChunk({ page: page + 1, perPage });
			return [...data.data, ...nextChunkData];
		}

		return data.data;
	};

	return useQuery(queryKey, () => fetchChunk({ page: 1, perPage: 100 }), {
		refetchOnWindowFocus: false,
		enabled: false,
	});
};
