import React from 'react';
import { Box, Tooltip } from '@material-ui/core';

// Import components
import { useTableQueryExportProvider } from 'components/context';
import { IconButton } from 'components/elements';

// Import helpers
import { EXPORT_TYPES } from 'helpers';

// Import icons
import { ExcelIcon, PdfIcon } from 'icons';

// Import utilities
import { useTranslations } from 'components/utilities';

export const TableQueryExportButtons = () => {
	const { openExport } = useTableQueryExportProvider();

	const { t } = useTranslations();

	return (
		<Box>
			<Tooltip title={t('common.icon_names.excel')}>
				<span>
					<IconButton onClick={() => openExport(EXPORT_TYPES.EXCEL)}>
						<ExcelIcon />
					</IconButton>
				</span>
			</Tooltip>
			<Tooltip title={t('common.icon_names.pdf')}>
				<span>
					<IconButton onClick={() => openExport(EXPORT_TYPES.PDF)}>
						<PdfIcon />
					</IconButton>
				</span>
			</Tooltip>
		</Box>
	);
};
