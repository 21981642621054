import React from 'react';
import { oneOfType, node, arrayOf, object, func, any } from 'prop-types';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import _ from 'lodash';

// Import components
import { TableQueryExportFiltersContent } from './TableQueryExportFiltersContent';

// Import utils
import { useTableQueryExportProvider } from 'components/context';

export const TableQueryExportFilters = ({
	children,
	initialValues,
	formatValues,
	setCustomFilters,
}) => {
	const { setFilters } = useTableQueryExportProvider();

	const handleSubmit = (values) => {
		const newValues = formatValues ? formatValues(values) : values;

		if (_.isFunction(setCustomFilters)) {
			setCustomFilters(newValues);
		}

		setFilters(newValues);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={initialValues}
			render={(props) => (
				// eslint-disable-next-line
				<Box component="form" width="100%" onSubmit={props.handleSubmit}>
					<TableQueryExportFiltersContent>
						{children}
					</TableQueryExportFiltersContent>
				</Box>
			)}
		></Form>
	);
};

TableQueryExportFilters.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
	initialValues: object,
	formatValues: func,
	setCustomFilters: any,
};
