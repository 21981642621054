import React from 'react';
import { func } from 'prop-types';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Box,
	Divider,
} from '@material-ui/core';

// Import components
import { useTableQueryExportProvider } from 'components/context';
import { Alert } from 'components/elements';
import {
	TableQueryExportColumns,
	TableQueryExportProgress,
	TableQueryExportHeader,
} from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useTableQueryExport } from './useTableQueryExport';

export const TableQueryExport = ({ renderFilters }) => {
	const { open, hideExport, isLoading, error } = useTableQueryExportProvider();

	const { handleExport } = useTableQueryExport();

	const { t } = useTranslations();

	return (
		<Dialog open={open} onClose={hideExport} fullWidth maxWidth="xl">
			<DialogContent>
				<Box py={2}>
					<TableQueryExportHeader />
					<TableQueryExportColumns />
				</Box>
				{renderFilters && (
					<Box py={2}>
						<Typography variant="h5" gutterBottom>
							{t('table_export.apply_filters')}
						</Typography>
						{renderFilters()}
					</Box>
				)}

				{isLoading && (
					<>
						<Box py={2}>
							<TableQueryExportProgress />
						</Box>
					</>
				)}

				{error && (
					<>
						<Divider />
						<Box py={2}>
							<Alert message={error} />
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={hideExport}>
					{t('table_export.cancel')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={isLoading}
					onClick={handleExport}
				>
					{t('table_export.export')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

TableQueryExport.propTypes = {
	renderFilters: func,
};
