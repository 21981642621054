import React from 'react';

// Import utils and helpers
import { TableRPCWithQuery, useProfile } from 'components/utilities';
import { getColumns } from './columns';
import { ENDPOINTS, QUERY_KEYS } from 'helpers';
import {
	getFormattedFilterValues,
	INVOICES_LIST_ROWS_PER_PAGE,
	INITIAL_VALUES,
} from './helpers';

// Import components
import { OuterFilters, Export } from './components';

export const InvoicesList = () => {
	const { isAdmin } = useProfile();

	return (
		<TableRPCWithQuery
			url={ENDPOINTS.INVOICES.INVOICES_LIST}
			queryKey={QUERY_KEYS.INVOICES.INVOICES_LIST}
			title="invoices.invoices_list"
			columns={getColumns(isAdmin)}
			rowsPerPageOptions={INVOICES_LIST_ROWS_PER_PAGE}
			filters={<OuterFilters />}
			getFormattedFilterValues={getFormattedFilterValues}
			filtersInitialValues={INITIAL_VALUES}
			outerToolbarElements={<Export />}
		/>
	);
};
