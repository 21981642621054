import React from 'react';

// Import components
import {
	DateRangeInput,
	Grid,
	MultiCompaniesFilter,
	MultiOfficesFilter,
} from 'components/elements';

// Import utils and helpers
import { useProfile } from 'components/utilities';

export const DialogFilters = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item md={6}>
				<DateRangeInput
					label="common.fields.created_at"
					nameUntil="created.until"
					nameFrom="created.from"
					combinedInputs
				/>
			</Grid>
			{isAdmin && (
				<MultiCompaniesFilter
					label="common.fields.company"
					name="company_uuid"
					md={6}
				/>
			)}

			{!isAdmin && (
				<MultiOfficesFilter
					label="common.fields.office"
					name="office_uuid"
					md={6}
				/>
			)}
		</Grid>
	);
};
